import { FileData } from '../types/files'
import { t } from 'i18next'
import { MainData } from 'index'
import { createFileUploadedData, createImageData } from '../api/data'
import { useSnackbar } from './useSnackbar'
import { FileExtList } from '../components/Icon'
import { SelectableFileData } from 'index'

const useFileUtils = () => {
    const { setErrorMsg } = useSnackbar()

    const imageTypes = ['png', 'jpg', 'jpeg', 'svg', 'JPG']
    const fileTypes = ['html', 'htm', 'pdf', 'txt']

    const acceptedImages = { 'image/png': imageTypes }
    const acceptedFileTypes = { 'text/html': fileTypes }

    const allowedFileTypes = [...imageTypes, ...fileTypes]

    const handleCreateImageData = (data: File[]) => {
        return data.map((item: File) =>
            createImageData({ url: URL.createObjectURL(item), filename: item?.name })
        )
    }

    const handleCreateFileData = (data: File[]) => {
        if (!data?.length) setErrorMsg(`${t('file_type_error')} ${fileTypes.join(' ')}`)

        return data.map((item: File) => createFileUploadedData(item))
    }

    const handleCreateSelectionFileData = (data: File[]): SelectableFileData[] => {
        return data
            .map((item: File) => {
                const typeFile = item.type.split('/')[1]
                const url = URL.createObjectURL(item)
                if (imageTypes.includes(typeFile)) {
                    return { src: url, filename: item.name, type: 'image' as const }
                } else if (fileTypes.includes(typeFile)) {
                    return { src: url, filename: item.name, type: 'pdf' as const }
                } else {
                    setErrorMsg(`${t('file_type_error')} ${allowedFileTypes.join(', ')}`)
                    return { src: url, filename: item.name, type: undefined }
                }
            })
            .filter((item): item is SelectableFileData => item !== null)
    }

    const detectFileType = (filename: string) => {
        const ext = filename.split('.').pop()?.toLowerCase()
        if (ext && imageTypes.includes(ext)) return 'image'
        if (ext && fileTypes.includes(ext)) return 'pdf'
        return undefined
    }

    const isFile = (item: MainData): item is FileData => {
        return Boolean('ext' in item)
    }

    const isImage = (item: MainData | FileData): item is FileData => {
        return !!isFile(item) && imageTypes.includes(item.ext)
    }

    const isPDF = (ext: FileExtList): ext is 'pdf' => {
        return ext === 'pdf'
    }

    return {
        handleCreateFileData,
        handleCreateImageData,
        handleCreateSelectionFileData,
        isImage,
        isFile,
        isPDF,
        imageTypes,
        fileTypes,
        allowedFileTypes,
        acceptedImages,
        acceptedFileTypes,
        detectFileType,
    }
}

export default useFileUtils
