// src/modules/SelectableFileUpload/index.tsx
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Image from '../../components/Image';
import ImageViewerWithSelection from '../../components/ImageViewerWithSelection';
import PdfViewer from '../../components/PdfViewer';
import PdfViewerWithSelection from '../../components/PdfViewerWithSelection';
import Placeholder from '../../components/Placeholder';
import useFileUpload from '../../hooks/useFileUpload';
import useFileUtils from '../../hooks/useFileUtils';
import { SelectableFileData as FileData } from '../../types';
import FileUpload from '../FileIUpload';
import { StyledImageContainer, StyledImageWrapper } from '../ImageUpload/styles';

interface Props {
      name: string;
      onUploadEnd?: () => void;
      file?: FileData;
}

function SelectableFileUpload({ name, onUploadEnd, file: initialFile }: Props): JSX.Element {
      const { t } = useTranslation();
      const { setValue, getValues } = useFormContext();
      const { handleCreateSelectionFileData, imageTypes, fileTypes, detectFileType } = useFileUtils();
      const { uploadInputRef, handleUploadBtnClick, resetInput } = useFileUpload();
      const {
            palette: { success: { main } },
      } = useTheme();

      const [file, setFile] = useState<FileData | undefined>(() => {
            if (initialFile) {
                  return {
                        ...initialFile,
                        type: detectFileType(initialFile.filename),
                  };
            }
            return initialFile;
      });

      const setFormValue = (value?: FileData) => {
            setValue(name, value, {
                  shouldValidate: true,
                  shouldDirty: true,
            });
      };

      const handleRemove = () => {
            setFormValue();
            setFile(undefined);
            setValue(`${name}_offset_x`, 0);
            setValue(`${name}_offset_y`, 0);
            setValue(`${name}_box_width`, 0);
            setValue(`${name}_box_height`, 0);
      };

      const handleUpload = (files: File[], onSettled: () => void) => {
            const newFiles = handleCreateSelectionFileData(files);
            if (newFiles.length > 0) {
                  setFormValue(newFiles[0]);
                  setFile(newFiles[0]);
                  setShowFileViewer(true);
                  setValue(`${name}_offset_x`, 0);
                  setValue(`${name}_offset_y`, 0);
                  setValue(`${name}_box_width`, 0);
                  setValue(`${name}_box_height`, 0);
            }
            onSettled();
            onUploadEnd && onUploadEnd();
      };

      const ButtonAddFile = () => (
            <Button
                  variant="contained"
                  component="label"
                  onClick={handleUploadBtnClick}
                  size="large"
                  sx={{ flexShrink: 0 }}
                  startIcon={<AddIcon />}
            >
                  {t('file.add_files')}
            </Button>
      );

      const [showFileViewer, setShowFileViewer] = useState(false);

      useEffect(() => {
            resetInput();
      }, [file, resetInput]);

      const render = () => (
            <Box>
                  {file && (
                        <>
                              {file.type === 'image' && (
                                    <StyledImageWrapper onClick={() => setShowFileViewer(true)}>
                                          <StyledImageContainer>
                                                <Image src={file.src} />
                                          </StyledImageContainer>
                                    </StyledImageWrapper>

                              )}

                              {file.type === 'pdf' && (
                                    <StyledImageWrapper onClick={() => setShowFileViewer(true)}>
                                          <StyledImageContainer>
                                                <PdfViewer src={file.src} />
                                          </StyledImageContainer>
                                    </StyledImageWrapper>
                              )}

                              <Box display="flex" alignItems={'center'} gap={4} mt={2}>
                                    <ButtonAddFile />
                                    {file.filename && (
                                          <Box display="flex" alignItems={'center'} color={main}>
                                                <CheckCircleOutlineIcon sx={{ mr: 2 }} />
                                                <Typography fontSize={12} fontWeight={600}>
                                                      {file.filename}
                                                </Typography>
                                          </Box>
                                    )}

                                    <IconButton onClick={handleRemove} sx={{ ml: 2 }}>
                                          <CloseIcon />
                                    </IconButton>
                              </Box>
                        </>
                  )}

                  {!file && (
                        <>
                              <Placeholder onClick={handleUploadBtnClick} type="image_and_pdf" />
                              <ButtonAddFile />
                        </>
                  )}
            </Box>
      );

      return (
            <>
                  <FileUpload
                        variant="upload"
                        accepted={[...imageTypes, ...fileTypes]}
                        inputRef={uploadInputRef}
                        onUpload={handleUpload}
                        isDropAllow={true}
                  >
                        {render()}
                  </FileUpload>
                  {showFileViewer && file && (
                        file.type === 'pdf' ? (
                              <PdfViewerWithSelection
                                    pdfUrl={file.src}
                                    onExit={() => setShowFileViewer(false)}
                                    onSave={(data) => {
                                          setShowFileViewer(false);
                                          setValue(`${name}_offset_x`, data.x, { shouldDirty: true });
                                          setValue(`${name}_offset_y`, data.y, { shouldDirty: true });
                                          setValue(`${name}_box_width`, data.width, { shouldDirty: true });
                                          setValue(`${name}_box_height`, data.height, { shouldDirty: true });
                                    }}
                                    initialSelection={{
                                          x: getValues(`${name}_offset_x`),
                                          y: getValues(`${name}_offset_y`),
                                          width: getValues(`${name}_box_width`),
                                          height: getValues(`${name}_box_height`),
                                    }}
                              />
                        ) : (
                              <ImageViewerWithSelection
                                    imageUrl={file.src}
                                    initialSelection={{
                                          x: getValues(`${name}_offset_x`),
                                          y: getValues(`${name}_offset_y`),
                                          width: getValues(`${name}_box_width`),
                                          height: getValues(`${name}_box_height`),
                                    }}
                                    onExit={() => setShowFileViewer(false)}
                                    onSave={(data) => {
                                          setShowFileViewer(false);
                                          setValue(`${name}_offset_x`, data.x, { shouldDirty: true });
                                          setValue(`${name}_offset_y`, data.y, { shouldDirty: true });
                                          setValue(`${name}_box_width`, data.width, { shouldDirty: true });
                                          setValue(`${name}_box_height`, data.height, { shouldDirty: true });
                                    }}
                              />
                        )
                  )}
            </>
      );
}

export default SelectableFileUpload;
