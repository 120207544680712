import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Image from '../../components/Image';
import ImageViewerWithSelection from '../../components/ImageViewerWithSelection';
import Placeholder from '../../components/Placeholder';
import useFileUpload from '../../hooks/useFileUpload';
import useFileUtils from '../../hooks/useFileUtils';
import { ImageData } from '../../types';
import FileUpload from '../FileIUpload';
import ImageGallery from './components/ImageGallery';
import ImageListItem from './components/ImageListItem';
import { StyledImageContainer, StyledImageWrapper } from './styles';

interface Props {
    name: string;
    onUploadEnd?: () => void;
    selectable?: boolean;
}

type SingleVariantProps = {
    variant: 'single';
    image: ImageData | undefined;
} & Props;

type MultiVariantProps = {
    variant: 'gallery' | 'list';
    images: (ImageData | string)[];
} & Props;

function ImageUpload(props: SingleVariantProps): JSX.Element;
function ImageUpload(props: MultiVariantProps): JSX.Element;
function ImageUpload({
    name,
    variant,
    onUploadEnd,
    selectable = false,
    ...props
}: (SingleVariantProps | MultiVariantProps) & Props): JSX.Element {
    const { t } = useTranslation();
    const { setValue, getValues } = useFormContext();
    const { handleCreateImageData, imageTypes } = useFileUtils();
    const { uploadInputRef, handleUploadBtnClick, resetInput } = useFileUpload();
    const {
        palette: { success: { main } },
    } = useTheme();

    const [image, images] = [
        'image' in props ? props?.image : undefined,
        'images' in props ? props?.images : undefined,
    ];
    const imagesData = images?.filter((img): img is ImageData => typeof img !== 'string') || [];

    const setFormValue = (values?: ImageData | (ImageData | string)[]) => {
        setValue(name, values, {
            shouldValidate: true,
            shouldDirty: true,
        });
    };

    const handleRemove = () => {
        setFormValue();
        if (selectable) {
            setValue(`${name}_offset_x`, 0);
            setValue(`${name}_offset_y`, 0);
            setValue(`${name}_box_width`, 0);
            setValue(`${name}_box_height`, 0);
        }
    };

    const handleRemoveFromList = (src: string) => {
        const result = images?.map((item) => {
            if (typeof item === 'string') return item;
            if (item?.src === src) return '';
            return item;
        });
        if (!result) return;
        setFormValue(result);
    };

    const handleUpload = (files: File[], onSettled: () => void) => {
        const newImages = handleCreateImageData(files);

        if (variant === 'single') {
            setFormValue(newImages[0]);
            if (selectable) {
                setShowImageViewer(true);
                setValue(`${name}_offset_x`, 0);
                setValue(`${name}_offset_y`, 0);
                setValue(`${name}_box_width`, 0);
                setValue(`${name}_box_height`, 0);
            }
        } else {
            setFormValue([...(images || []), ...newImages]);
        }
        onSettled();
        onUploadEnd && onUploadEnd();
    };

    const ButtonAddFile = () => (
        <Button
            variant="contained"
            component="label"
            onClick={handleUploadBtnClick}
            size="large"
            sx={{ flexShrink: 0 }}
            startIcon={<AddIcon />}
        >
            {t('add_image')}
        </Button>
    );

    const [showImageViewer, setShowImageViewer] = useState(false);

    useEffect(() => {
        resetInput();
    }, [image, resetInput]);

    const render = () => {
        if (variant === 'gallery' && images?.length) return <ImageGallery images={imagesData} />;

        return (
            <Box>
                {variant === 'list' && (
                    <Box m={-2} mb={2}>
                        {imagesData?.map((image: ImageData, i: number) => (
                            <ImageListItem
                                key={i}
                                onDeleteClick={handleRemoveFromList}
                                index={i + 1}
                                image={image}
                            />
                        ))}
                    </Box>
                )}

                {image && (
                    <>
                        <StyledImageWrapper onClick={() => selectable && setShowImageViewer(true)}>
                            <StyledImageContainer>
                                <Image src={image?.src} />
                            </StyledImageContainer>
                        </StyledImageWrapper>

                        <Box display="flex" alignItems={'center'} gap={4} mt={2}>
                            <ButtonAddFile />
                            {image?.filename && (
                                <Box display="flex" alignItems={'center'} color={main}>
                                    <CheckCircleOutlineIcon sx={{ mr: 2 }} />
                                    <Typography fontSize={12} fontWeight={600}>
                                        {image.filename}
                                    </Typography>
                                </Box>
                            )}

                            <IconButton onClick={handleRemove} sx={{ ml: 2 }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </>
                )}

                {!imagesData?.length && !image && (
                    <Placeholder onClick={handleUploadBtnClick} type="image" />
                )}

                {!image && <ButtonAddFile />}
            </Box>
        );
    };

    return (
        <>
            <FileUpload
                variant="upload"
                accepted={imageTypes}
                inputRef={uploadInputRef}
                onUpload={handleUpload}
                isDropAllow={true}
            >
                {render()}
            </FileUpload>
            {showImageViewer && image && selectable && (
                <ImageViewerWithSelection
                    imageUrl={image.src}
                    initialSelection={{
                        x: getValues(`${name}_offset_x`),
                        y: getValues(`${name}_offset_y`),
                        width: getValues(`${name}_box_width`),
                        height: getValues(`${name}_box_height`),
                    }}
                    onExit={() => setShowImageViewer(false)}
                    onSave={(data) => {
                        setShowImageViewer(false);
                        setValue(`${name}_offset_x`, data.x);
                        setValue(`${name}_offset_y`, data.y);
                        setValue(`${name}_box_width`, data.width);
                        setValue(`${name}_box_height`, data.height);
                    }}
                />
            )}
        </>
    );
}

export default ImageUpload;
